/**
 * Default value for showing the guidelines and controls.
 */
export const SHOW_LAYOUT_GRID = true;

/**
 * Default value for showing the safezone and controls.
 */
export const SHOW_SAFE_ZONES = true;

/**
 * Default value for showing the rulers in the canvas.
 */
export const SHOW_RULERS = true;

/**
 * Default value for showing the timeline.
 */
export const SHOW_TIMELINE = false;
