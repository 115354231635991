import { SafeZone } from '../types/formatProperties.type';

const defaultSafeZone: {
    activeLayout: NonNullable<SafeZone['activeLayout']>;
    color: NonNullable<SafeZone['color']>;
} = {
    activeLayout: '',
    color: {
        hex: '#ff000080',
        rgb: { r: 255, g: 0, b: 0, a: 0.5 }
    }
};

export default defaultSafeZone;
